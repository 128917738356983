<template>
	<div class="place-count-box">
		<div class="centerBox">
			<div class="tab">
				<div class="tab-item" :class="{ active: item.value == tabsIndex }" v-for="(item, index) in tabs"
					:key="index" @click="changeTabs(index)">
					{{ item.name }}
				</div>
			</div>
			<div class="data-box">
				<template v-if="tabsIndex == 0">
					<div class="option-box">
						<Form :model="nowFormItem">
							<FormItem label="交易状态">
								<RadioGroup v-model="nowFormItem.tradingStatus">
									<Radio v-for="(item, index) in dealState" :key="index" :label="item.id">
										{{ item.name }}
									</Radio>
								</RadioGroup>
							</FormItem>
							<FormItem label="土地用途">
								<Checkbox :value="checkLandAll" style="display: inline" @click.prevent.native="
                    handleCheckAll(checkLandAll, landUse, 'landUses')
                  ">全选</Checkbox>
								<CheckboxGroup v-model="nowFormItem.landUses" style="display: inline">
									<Checkbox v-for="(item, index) in landUse" :key="index" :label="item.id">
										{{ item.name }}
									</Checkbox>
								</CheckboxGroup>
							</FormItem>
							<FormItem label="周期">
								<RadioGroup v-model="nowFormItem.time">
									<Select v-model="nowFormItem.timePeriod" @on-change="changeTimePeriod"
										style="width: 70px">
										<Option v-for="item in timeList" :value="item.id" :key="item.id">{{ item.name }}
										</Option>
									</Select>
									<DatePicker :type="timeType || 'year'" :disabled="!nowFormItem.timePeriod"
										@on-change="changeTime(1)" v-model="nowFormItem.timeStart" placeholder="开始时间"
										style="width: 96px; margin-left: 11px"></DatePicker>
									<Select v-model="nowFormItem.quarterStart" v-show="nowFormItem.timePeriod == 2"
										@on-change="checkTime" placeholder="季度" style="width: 70px">
										<Option v-for="item in quarterList" :value="item" :key="item">{{ item }}
										</Option>
									</Select>-
									<DatePicker :type="timeType || 'year'" :disabled="!nowFormItem.timePeriod"
										v-model="nowFormItem.timeEnd" @on-change="changeTime(2)" placeholder="结束时间"
										style="width: 96px"></DatePicker>
									<Select v-model="nowFormItem.quarterEnd" v-show="nowFormItem.timePeriod == 2"
										@on-change="checkTime" placeholder="季度" style="width: 70px">
										<Option v-for="item in quarterList" :value="item" :key="item">{{ item }}
										</Option>
									</Select>
								</RadioGroup>
							</FormItem>
							<!-- <FormItem label="出让方式">
                <Checkbox :value="checkSellAll"
                          style="display:inline;"
                          @click.prevent.native="handleCheckAll(checkSellAll,sellWay,'sellTypes')">全选</Checkbox>
                <CheckboxGroup v-model="nowFormItem.sellTypes"
                               style="display:inline;">
                  <template v-for="(item,index) in sellWay">
                    <Checkbox :key="index"
                              :label="item.id">{{item.name}}</Checkbox>
                  </template>
                </CheckboxGroup>
              </FormItem>-->
							<FormItem label="指标选择">
								<Checkbox :value="checkOptionsAll" style="display: inline" @click.prevent.native="
                    handleCheckAll(
                      checkOptionsAll,
                      indexOptions,
                      'indexOptions'
                    )
                  ">全选</Checkbox>
								<CheckboxGroup v-model="nowFormItem.indexOptions" style="display: inline">
									<Checkbox v-for="(item, key, index) in indexOptions" :disabled="
                      nowFormItem.tradingStatus != 1 &&
                      tradeIndexOption.indexOf(key) > -1
                    " :key="index" :label="key">{{ item.name }}</Checkbox>
								</CheckboxGroup>
							</FormItem>
						</Form>
					</div>
					<div class="option-box">
						<div class="title">选择区域</div>
						<areaSelect ref="areaSelect" radio='multiple' :isHideProvince='true'
							:ProvinceList='landProvinceList'></areaSelect>
					</div>
				</template>

				<template v-if="tabsIndex == 1">
					<div class="option-box">
						<Form :model="nowFormItem">
							<FormItem label="周期">
								<RadioGroup v-model="nowFormItem.time">
									<Select v-model="nowFormItem.timePeriod" @on-change="changeTimePeriod"
										style="width: 70px">
										<Option v-for="item in timeList" :value="item.id" :key="item.id">{{ item.name }}
										</Option>
									</Select>
									<DatePicker :type="timeType || 'year'" :disabled="!nowFormItem.timePeriod"
										@on-change="changeTime(1)" v-model="nowFormItem.timeStart" placeholder="开始时间"
										style="width: 96px; margin-left: 11px"></DatePicker>
									<Select v-model="nowFormItem.quarterStart" v-show="nowFormItem.timePeriod == 2"
										@on-change="checkTime" placeholder="季度" style="width: 70px">
										<Option v-for="item in quarterList" :value="item" :key="item">{{ item }}
										</Option>
									</Select>-
									<DatePicker :type="timeType || 'year'" :disabled="!nowFormItem.timePeriod"
										v-model="nowFormItem.timeEnd" @on-change="changeTime(2)" placeholder="结束时间"
										style="width: 96px"></DatePicker>
									<Select v-model="nowFormItem.quarterEnd" v-show="nowFormItem.timePeriod == 2"
										@on-change="checkTime" placeholder="季度" style="width: 70px">
										<Option v-for="item in quarterList" :value="item" :key="item">{{ item }}
										</Option>
									</Select>
								</RadioGroup>
							</FormItem>
							<!-- <FormItem label="出让方式">
                <Checkbox :value="checkSellAll"
                          style="display:inline;"
                          @click.prevent.native="handleCheckAll(checkSellAll,sellWay,'sellTypes')">全选</Checkbox>
                <CheckboxGroup v-model="nowFormItem.sellTypes"
                               style="display:inline;">
                  <template v-for="(item,index) in sellWay">
                    <Checkbox :key="index"
                              :label="item.id">{{item.name}}</Checkbox>
                  </template>
                </CheckboxGroup>
              </FormItem>-->
							<FormItem label="土地用途">
								<Checkbox :value="checkLandAll" style="display: inline" @click.prevent.native="
                    handleCheckAll(checkLandAll, landUse, 'landUses')
                  ">全选</Checkbox>
								<CheckboxGroup v-model="nowFormItem.landUses" style="display: inline">
									<Checkbox v-for="item in landUse" :key="item.id" :label="item.id">{{ item.name }}
									</Checkbox>
								</CheckboxGroup>
							</FormItem>
							<FormItem label="指标选择">
								<Checkbox :value="checkOptionsAll" style="display: inline" @click.prevent.native="
                    handleCheckAll(
                      checkOptionsAll,
                      indexOptions,
                      'indexOptions'
                    )
                  ">全选</Checkbox>
								<CheckboxGroup v-model="nowFormItem.indexOptions" style="display: inline">
									<template v-for="(item, key, index) in indexOptions">
										<Checkbox v-if="key != 'With' && key != 'Loop'" :key="index" :label="key">
											{{ item.name }}
										</Checkbox>
									</template>
								</CheckboxGroup>
							</FormItem>
							<FormItem label="选择区域" class="com-city-box">
								<div class="line">
									<!-- <RadioGroup v-model="nowFormItem.radio">
                    <Radio label="single">单城市</Radio>
                  </RadioGroup>-->
									<Select v-model="nowFormItem.province" placeholder="省份" @on-change="chooseProvince2"
										style="width: 74px; margin-right: 7px">
										<Option v-for="item in landProvinceList" :value="item.areaId"
											:key="item.areaId">{{ item.name }}</Option>
									</Select>

									<div class="select-box">
										<Select v-model="nowFormItem.city" placeholder="城市" multiple
											class="multiple-select" style="width: 74px; margin-right: 7px">
											<Option v-for="item in nowFormItem.cityList" :value="item.areaId"
												:key="item.areaId">{{ item.name }}</Option>
										</Select>
										<div class="place" v-if="nowFormItem.city.length">城市</div>
									</div>

									<div class="show-city-box" v-if="nowFormItem.city.length">
										<Tag type="border" :key="index" size="large"
											v-for="(item, index) in nowFormItem.city"
											@on-close="delComCity(item, index)" closable>
											<span class="tabContent">{{formatCity(item, cityList)}}</span>
										</Tag>
									</div>
									<!-- <Select v-model="nowFormItem.region"
                          placeholder="行政区"
                          style="width:88px;">
                    <Option v-for="item in nowFormItem.regionList"
                            :value="item.areaId"
                            :key="item.areaId">{{ item.name }}
                    </Option>
                  </Select>-->
								</div>
							</FormItem>
						</Form>
					</div>
					<div class="option-box">
						<div class="title">选择企业</div>

						<div class="areaTransfer company">
							<div class="leftTransfer">
								<div class="leftTransferSearch">
									<Input v-model="nowFormItem.searchCity" placeholder="输入关键字搜索"
										@on-change="searchCom" />
									<span class="btn-all" @click="mutliSelectAll">全选</span>
								</div>

								<div class="leftTransferBottom">
									<div class="leftRadio">
										<RadioGroup v-model="nowFormItem.multiCityRadio" @on-change="getMultiCom">
											<Radio v-for="(item, index) in enterprise" :disabled="index == 0"
												:key="index" :label="item.id">{{ item.name }}</Radio>
										</RadioGroup>
									</div>
									<div class="rightBox">
										<template v-if="nowFormItem.filterCityList.length">
											<div class="item" v-for="(item, index) in nowFormItem.filterCityList"
												@click="chooseCity(item)" :key="index">
												{{ item.name }}
											</div>
										</template>
										<template v-else>
											<div class="item" v-for="(item, index) in nowFormItem.multiCityList"
												@click="chooseCity(item)" :key="index">
												{{ item.name }}
											</div>
										</template>
									</div>
								</div>
							</div>

							<Icon type="md-arrow-round-forward" class="centerBtn" />

							<div class="rightTransfer">
								<div class="topBtn">
									已选
									<span @click="clearChooseCity">清空</span>
								</div>
								<div class="itemBox">
									<Tooltip max-width="300" :content="item.name" theme="light"
										v-for="(item, index) in nowFormItem.chooseCityList" :key="index">
										<Tag closable class="item" color="#00B6FF" @on-close="delCity(item, index)">
											{{ item.name }}
										</Tag>
									</Tooltip>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>

			<div class="btn-box">
				<div class="btn" @click="saveTemplate">保存模板</div>
				<div class="btn check searchBtnLog" data-id="1" @click="search">查询</div>
				<div class="btn" @click="manageModalSwitch">我的模板</div>
			</div>

			<div class="choose-box">
				<TagGroup :tagList="selectList" @close="delSingle">
					<Tag type="border" size="large">
						出让方式:
						<span class="tabContent">招拍挂</span>
					</Tag>
					<Tag type="border" v-if="nowFormItem.optionTime" size="large" @on-close="delTime" >
						周期:
						<span class="tabContent">{{ nowFormItem.optionTime }}</span>
					</Tag>

					<Tag type="border" v-if="
              nowFormItem.radio == 'single' &&
              tabsIndex == 0 &&
              nowFormItem.province &&
              nowFormItem.city
            " size="large" @on-close="delChooseCity">
						区域:
						<span class="tabContent">
							{{ formatProvince(nowFormItem.province) }}
							{{ formatCity(nowFormItem.city, nowFormItem.cityList) }}
							{{ formatRegion(nowFormItem.region, nowFormItem.regionList) }}
						</span>
					</Tag>

					<Tag type="border" v-if="tabsIndex == 1 && nowFormItem.city.length" size="large"
						@on-close="delChooseCity">
						区域:
						<span class="tabContent">
							<template v-for="item in nowFormItem.city">{{ formatCity(item, cityList)}}</template>
						</span>
					</Tag>
					<Tag type="border" size="large" v-if="
              nowFormItem.chooseCityList.length &&
              (nowFormItem.radio == 'multiple' || tabsIndex == 1)
            ">
						{{ tabsIndex ? "企业" : "区域" }}:
						<span class="tabContent">
							<template v-for="item in nowFormItem.chooseCityList">{{item.name}}</template>
						</span>
					</Tag>
				</TagGroup>
			</div>

			<div class="table-box">
				<div class="top">
					<DownloadBtn :id="controlId" @exportData="exportData" :type=1
						:style="{ 'background-color': 'rgba(241, 110, 113, 1)' }"></DownloadBtn>

					<div class="select-box" v-if="theadType == 1">
						<Select size="small" v-model="showDetail" style="width: 120px; marginright: 10px">
							<Option v-for="item in detailOpt" :value="item.value" :key="item.value">{{ item.label }}
							</Option>
						</Select>
						<div class="place">{{ tipsSelect }}</div>
					</div>
					<div class="table-opt" v-if="theadType == 2">
						<Select v-model="selectRenderCity" style="width: 120px; marginright: 10px" size="small"
							v-if="tabsIndex == 0" placeholder="区域">
							<Option v-for="item in nowFormItem.chooseCityList" :value="item.name" :key="item.id">
								{{ item.name }}
							</Option>
						</Select>
						<Select v-model="selectRenderCity" style="width: 120px; marginright: 10px" size="small"
							v-if="tabsIndex == 1" placeholder="区域">
							<Option v-for="item in nowFormItem.city" :value="formatCity(item, cityList)" :key="item">
								{{ formatCity(item, cityList) }}
							</Option>
						</Select>

						<Select v-model="selectRenderIndex" style="width: 200px" size="small" placeholder="指标">
							<template v-for="item in nowFormItem.indexOptions">
								<Option v-if="item != 'With' && item != 'Loop'" :value="indexOptions[item].name"
									:key="item">{{ indexOptions[item].name }}</Option>
							</template>
						</Select>
					</div>

					<div class="icon-box" v-if="tabsIndex == 0">
						<div v-for="item in theadOptList" :key="item.type"
							:class="['icon left', { active: theadType === item.type }]" @click="switchThead(item.type)">
						</div>
					</div>
				</div>
				<Table v-if="theadType == 1" :columns="tableHeads" :data="nowFormItem.tableData" border
					tooltip-theme="light" height="550" class="table" :loading="loading"></Table>
				<Page v-if="theadType == 1" :current="nowFormItem.currentIndex" :total="nowFormItem.total" show-elevator
					show-sizer @on-page-size-change="pageSizeChange" @on-change="pageChange" />

				<div class="canvas-box" id="canvas" v-if="theadType == 2">
					<!-- <canvas id="canvas"></canvas> -->
				</div>
			</div>
		</div>
		<modalManage :saveType="modalSaveId" @chooseTemplate="chooseTemplate"></modalManage>
		<cityManage></cityManage>
		<devManage></devManage>
	</div>
</template>

<script>
	const echarts = require("echarts");
	import {
		mapState
	} from "vuex";
	import mixin from "@/mixins";
	import formMixin from "@/mixins/form";
	import modalManage from "@/components/modal/modalManage";
	import cityManage from "@/components/modal/cityManage";
	import devManage from "@/components/modal/devManage";
	import DownloadBtn from "@/components/DownloadBtn";
	import areaSelect from "@/components/areaSelect";

	import {
		dimSrf,
		dimSrfJt
	} from "@/api/public";
	import {
		getData,
		get_enterprise_data
	} from "@/api/placeMarket";
	import {
		getCityQuery
	} from "@/api/dataQuery";
	import {
		downloadData,
		formatJson,
		checkDate,
		localeDateStringtoTimestamp,
	} from "@/utils";
	import {
		getLandSingleSelectCity,
		getLandSingleSelectRegion,
		searchCity,
		getEnterprise,
	} from "@/api/public";
	import dataReports from "@/mixins/dataReports";

	export default {
		name: "home",
		components: {
			modalManage,
			cityManage,
			devManage,
			DownloadBtn,
			areaSelect
		},
		mixins: [mixin, formMixin, dataReports],
		data() {
			return {
				//弹框状态
				status2021: true,
				// 彈窗
				cityModal: false,
				manageModal: false,
				// tabs
				tabsIndex: 0,
				tipsSelect: "按土地用途",
				tabs: [{
						name: "按城市",
						value: 0,
					},
					{
						name: "按企业",
						value: 1,
					},
					// {
					// 	name: "排行榜",
					// 	value: 2,
					// },
				],
				// 筛选
				timeOptions: [{
						name: "年度",
						value: 1,
					},
					{
						name: "季度",
						value: 2,
					},
					{
						name: "月度",
						value: 3,
					},
					{
						name: "日",
						value: 4,
					},
				],
				areaSelect: [{
						name: "省",
						id: 1,
					},
					{
						name: "市",
						id: 2,
					},
				],
				cityOptions: [{
						label: "所有城市",
						id: 4,
					},
					{
						label: "一线城市",
						id: 1,
					},
					{
						label: "二线城市",
						id: 2,
					},
					{
						label: "三线城市",
						id: 3,
					},
				],
				cityOptions2: [{
						label: "粤港澳大湾区",
						id: 10,
					},
					{
						label: "京津冀",
						id: 6,
					},
					{
						label: "长三角",
						id: 7,
					},
					{
						label: "成渝",
						id: 8,
					},
					{
						label: "长江中游",
						id: 9,
					},
				],
				quarterList: ["Q1", "Q2", "Q3", "Q4"],
				// 选择交易状态的时候 用到的指标选项
				tradeIndexOption: [
					"transactionPrice",
					"floorPrice",
					"regionPrice",
					"premiumRate",
				],

				// 表格相關
				indexOptions: {
					religion: {
						name: "宗数",
						unit: "",
					},
					landArea: {
						name: "土地面积",
						unit: "（㎡）",
					},
					planArea: {
						name: "规划建筑面积",
						unit: "（㎡）",
					},
					startPrice: {
						name: "起始价",
						unit: "（万元）",
					},
					// startFloor: {
					//   name: "楼面价",
					//   unit: "（元/㎡）"
					// },
					// startRegion: {
					//   name: "起始地面价",
					//   unit: "（元/㎡）"
					// },
					transactionPrice: {
						name: "成交价",
						unit: "（万元）",
					},
					floorPrice: {
						name: "楼面价",
						unit: "（元/㎡）",
					},
					regionPrice: {
						name: "地面价",
						unit: "（元/㎡）",
					},
					premiumRate: {
						name: "溢价率",
						unit: "",
					},
					With: {
						name: "同比",
						unit: "",
					},
					Loop: {
						name: "环比",
						unit: "",
					},
				},

				showDetail: 1,
				detailOpt: [{
						value: 1,
						label: "分类统计",
					},
					{
						value: 2,
						label: "合并统计",
					},
				],
				// tab1用
				formItem: {
					areaValue: 2,
					multiCityList: [],
					indexOptions: [],
					tradingStatus: 1,
					timeStart: "201901",
					timeEnd: "202001",
					optionTime: "",
					province: "",
					city: "",
					region: [],
					timePeriod: 1,
					sellTypes: [],
					landUses: [],
					cityList: [],
					regionList: [],
					radio: "multiple",
					multiCityRadio: 1,
					multiCityRadio2: '',
					filterCityList: [],
					chooseCityList: [],
					tableData: [],
					pageSize: 10,
					currentIndex: 1,
					quarterStart: "Q1",
					quarterEnd: "Q1",
				},
				formItem1: {},
				formItem2: {},
				formItem3: {},
				cityList: [],

				loading: false,
				// 渲染图表用
				selectRenderCity: "",
				selectRenderLandUse: "",
				selectRenderSellType: "",
				selectRenderIndex: "",
				canvasColor: [
					"rgb(0,176,240)",
					"rgb(255,196,0)",
					"rgb(255,0,0)",
					"rgb(146,208,80)",
					"rgb(255,255,0)",
					"rgb(112,48,160)",
				],

				theadType: 1,
				theadOptList: [{
						type: 1,
						background: "rgb(76, 201, 233)",
					},
					{
						type: 2,
						background: "rgb(76, 201, 233)",
					},
				],
			};
		},
		watch: {
			showDetail: function(val) {
				this.search();
			},
			selectRenderIndex: function(val) {
				this.canvasData && this.canvasData.length && this.setCanvasOption();
			},
			selectRenderLandUse: function(val) {
				this.canvasData && this.canvasData.length && this.setCanvasOption();
			},
			selectRenderSellType: function(val) {
				this.canvasData && this.canvasData.length && this.setCanvasOption();
			},
			selectRenderCity: function(val) {
				this.canvasData && this.canvasData.length && this.setCanvasOption();
			},
			"nowFormItem.tradingStatus": function(val) {
				let option = Object.assign([], this.nowFormItem.indexOptions);
				if (val == 1) {
					option.push(...this.tradeIndexOption);
					this.nowFormItem.indexOptions = [...new Set(option)];
				} else {
					this.nowFormItem.indexOptions.remove("transactionPrice");
					this.nowFormItem.indexOptions.remove("floorPrice");
					this.nowFormItem.indexOptions.remove("regionPrice");
					this.nowFormItem.indexOptions.remove("premiumRate");
				}
			},
			"formItem2.city": function(val) {
				this.getMultiCom(this.formItem2.multiCityRadio);
			},
		},
		computed: {
			...mapState([
				"areaList",
				"landUse",
				"allLandUse",
				"sellWay",
				"dealState",
				"cityFlockList",
				"enterprise",
				"landProvinceList",
				"iframeUrl",
			]),
			// 模板数值
			modalSaveId() {
				return this.tabsIndex == 0 ? 7 : 8;
			},
			// 用来显示筛选文字用
			selectionOption() {
				return [{
						name: "交易状态",
						key: "tradingStatus",
						type: "option",
					},
					{
						name: "土地用途",
						key: "landUses",
						type: "list",
					},
					// {
					//     name : "出让方式",
					//     key : "sellTypes",
					//     type : 'list'
					// },
				];
			},
			optionList() {
				return {
					landUses: this.landUse,
					sellTypes: this.sellWay,
					tradingStatus: this.dealState,
				};
			},

			timeList() {
				if (this.$store.state.timeList.length) {
					let arr = Object.assign([], this.$store.state.timeList);
					return arr.reverse();
				} else {
					return [];
				}
			},
			nowFormItem() {
				if (this.tabsIndex == 0) return this.formItem1;
				if (this.tabsIndex == 1) return this.formItem2;
				return {};
			},
			filterSellWay() {
				let arr = [];
				let list = JSON.parse(JSON.stringify(this.sellWay));
				// list.splice(0, 1);
				// if(this.tabsIndex == 1) {
				// 	list.splice(list.length-1,1);
				// }
				if (list) arr = list;
				return arr;
			},
			checkSellAll() {
				return (
					this.nowFormItem.sellTypes &&
					this.nowFormItem.sellTypes.length == this.sellWay.length
				);
			},
			checkLandAll() {
				return (
					this.nowFormItem.landUses &&
					this.nowFormItem.landUses.length == this.landUse.length
				);
			},
			checkOptionsAll() {
				return (
					this.nowFormItem.indexOptions &&
					this.nowFormItem.indexOptions.length ==
					Object.keys(this.indexOptions).length
				);
			},
			checkSellCompose() {
				let sellTypes = this.nowFormItem.sellTypes;
				return (
					sellTypes &&
					sellTypes.includes(3) &&
					sellTypes.includes(4) &&
					sellTypes.includes(5)
				);
			},
			timeType() {
				let str = "";
				let timePeriod = this.nowFormItem.timePeriod;
				if (timePeriod == 4) {
					str = "date";
				} else if (timePeriod == 1) {
					str = "month";
				} else {
					str = "year";
				}
				return str;
			},

			showCityManage: {
				get() {
					return this.$store.state.modal.showCityManage;
				},
				set(newValue) {
					this.$store.state.modal.showCityManage = newValue;
				},
			},
			showDevManage: {
				get() {
					return this.$store.state.modal.showDevManage;
				},
				set(newValue) {
					this.$store.state.modal.showDevManage = newValue;
				},
			},
			showModalManage: {
				get() {
					return this.$store.state.modal.showModalManage;
				},
				set(newValue) {
					this.$store.state.modal.showModalManage = newValue;
				},
			},
			saveSearchData: {
				get() {
					return this.$store.state.saveSearchData;
				},
				set(newValue) {
					this.$store.state.saveSearchData = newValue;
				},
			},

			// table 的头部指标
			tableHeads() {
				let item = this.nowFormItem;
				let itemOptions = item.indexOptions;
				if (!item.indexOptions) return [];
				let indexOptions = this.indexOptions;
				let arr = [];
				let hasWith = itemOptions.indexOf("With") > -1 ? true : false;
				let hasLoop = itemOptions.indexOf("Loop") > -1 ? true : false;
				let filter = ["With", "Loop"];
				for (let key in indexOptions) {
					if (filter.indexOf(key) > -1) continue;
					if (itemOptions.indexOf(key) > -1) {
						let title = indexOptions[key].name;
						let unit = indexOptions[key].unit;
						arr.push({
							key,
							title: title,
							minWidth: 130,
							align: "center",
							render: (h, params) => {
								let val = "";
								if (key == "startPrice" || key == "transactionPrice") {
									val = params.row[key] ?
										`${Number((params.row[key] / 10000).toFixed(2))}` :
										0;
								} else if (key == "premiumRate") {
									val = params.row[key] ?
										Number((params.row[key] * 100).toFixed(2)) + "%" :
										"0.00%";
								} else {
									val = params.row[key] ? Number(params.row[key].toFixed(2)) : 0;
								}
								return h("span", val);
							},
							renderHeader: (h, index) => {
								let arr = [h("div", title)];
								if (unit) {
									arr.push(h("div", unit));
								}
								return h("div", arr);
							},
						});
						if (hasWith) {
							arr.push({
								key: `${key}With`,
								title: `${title}同比`,
								minWidth: 120,
								align: "center",
								render: (h, params) => {
									return h(
										"span",
										params.row[`${key}With`] ?
										Number((params.row[`${key}With`] * 100).toFixed(2)) + "%" :
										"-"
									);
								},
							});
						}
						if (hasLoop) {
							arr.push({
								key: `${key}Loop`,
								title: `${title}环比`,
								minWidth: 120,
								align: "center",
								render: (h, params) => {
									return h(
										"span",
										params.row[`${key}Loop`] ?
										Number((params.row[`${key}Loop`] * 100).toFixed(2)) + "%" :
										"-"
									);
								},
							});
						}
					}
				}
				arr.unshift(
					// {
					//   title: "周期",
					//   key: "timePeriodName",
					//   align: "center",
					//   minWidth: 100
					// },
					{
						title: "城市",
						key: "provinceName",
						align: "center",
						minWidth: 100,
					}
					// {
					// 	title: '出让方式',
					// 	key: 'sellTypeName',
					// 	align: 'center',
					// 	minWidth : 100,
					// }
					// ,
				);
				if (this.tabsIndex == 1) {
					arr.splice(0, -1, {
						title: "企业",
						key: "enterpriseName",
						align: "center",
						minWidth: 120,
						tooltip: true,
					});
				} else {
					arr.unshift({
						title: "时间",
						key: "landTime",
						align: "center",
						minWidth: 100,
					});
					arr.splice(2, 0, {
						title: "土地用途",
						key: "landUseName",
						align: "center",
						minWidth: 100,
						render: (h, params) => {
							return h(
								"span",
								this.showDetail == 1 ? params.row.landUseName : "合计"
							);
						},
					});
				}
				return arr;
			},
			controlId() {
				switch (this.tabsIndex) {
					case 0:
						return 1003;
					case 1:
						return 1004;
					default:
						return 1005;
				}
			},
		},
		beforeMount() {
			this.$Notice.destroy();
		},
		mounted() {
		/*	if (this.iframeUrl) {
				this.$router.push("/Insight/iframe?rPath=" + this.$route.path);
			}*/
			this.initData();
		},
		methods: {
			formatSellWay(id) {
				let str = "";
				this.sellWay.map((item) => {
					if (item.id == id) {
						str = item.name;
					}
				});
				return str;
			},
			async initData() {
				let formItem = this.formItem;
				let keys = Object.keys(this.indexOptions);
				formItem.indexOptions = keys.slice(0, keys.length - 2);
				// formItem.timeStart = '201901';
				// formItem.timeEnd = '202001';
				// this.checkTime();

				setTimeout(async () => {
					this.changeTimePeriod();
					await this.getMultiCity(formItem.multiCityRadio);
					this.formItem1.sellTypes = [3, 4, 5];
					this.formItem1.landUses = [1, 2, 3, 4];
					this.chooseCity(this.formItem1.multiCityList[2]);
					for (let key in this.$refs.areaSelect.nowFormItem) {
						this.formItem1[key] = this.$refs.areaSelect.nowFormItem[key]
					}
					this.formItem2.sellTypes = [3, 4, 5];
					this.formItem2.landUses = [1, 2, 3, 4];
					this.formItem2.multiCityRadio = 2;
					this.formItem2.province = 20;
					await this.chooseProvince2(this.formItem2.province);
					this.formItem2.city = [235];
					this.formItem2.indexOptions = [
						"religion",
						"landArea",
						"planArea",
						"startPrice",
						"transactionPrice",
						"floorPrice",
						"regionPrice",
						"premiumRate",
					];
					this.getList();
				});

				// 为每个tab分配一个参数一样的formItem
				this.tabs.map((item, index) => {
					let obj = JSON.parse(JSON.stringify(this.formItem));
					// this.$set(this,`formItem${index+1}`,obj);
					this[`formItem${index + 1}`] = obj;
				});
			},
			exportData() {
				const param = this.getSearchData();
				param.size = 10000000;
				param.current = 1;

				let listApi = this.tabsIndex == 0 ? getData : get_enterprise_data;
				listApi(param).then((data) => {
					const tableData = data.records;
					let filterVal = [];
					let tHeader = [];
					this.tableHeads.forEach((item) => {
						if (item.title == "土地面积") {
							item.title = "土地面积（㎡）";
						}
						if (item.title == "规划建筑面积") {
							item.title = "规划建筑面积（㎡）";
						}
						if (item.title == "起始价") {
							item.title = "起始价（万元）";
						}
						if (item.title == "成交价") {
							item.title = "成交价（万元）";
						}
						if (item.title == "楼面价") {
							item.title = "楼面价（万元/㎡）";
						}
						if (item.title == "地面价") {
							item.title = "地面价（万元/㎡）";
						}
						tHeader.push(item.title);
						filterVal.push(item.key);
					});
					const resData = formatJson(filterVal, tableData);
					downloadData(
						tHeader,
						resData,
						`土地统计(${this.tabsIndex == 0 ? "按城市" : "按企业"})`
					);
				});
			},
			changeTabs(index) {
				if (this.tabsIndex == index) return;
				this.tabsIndex = index;
				if (index == 0) {
					this.tipsSelect = "按土地用途";
				} else if (index == 1) {
					this.tipsSelect = "按城市";
				}

				this.$nextTick(() => {
					let formItem = this.nowFormItem;
					this.changeTimePeriod();
					if (this.tabsIndex == 1) {
						this.theadType = 1;
						this.getMultiCom(formItem.multiCityRadio);
						let originArr = this.formItem2.multiCityList;
						//当选择第二个tabs的时候，加入默认选择的企业
						let indexArr = [131852, 131851, 131583, 131582, 241720];
						originArr.forEach((item) => {
							if (indexArr.includes(item.id)) {
								this.chooseCity(item);
							}
						});
					}
					if (formItem.tableData.length == 0) {
						this.getList();
					}
				});
			},
			switchThead(value) {
				let nowFormItem = this.nowFormItem;
				this.theadType = value;
				if (value == 2) {
					if (this.selectRenderIndex == "") {
						this.selectRenderIndex = this.indexOptions[
							nowFormItem.indexOptions[0]
						].name;
					}
					if (this.selectRenderCity == "") {
						this.selectRenderCity = nowFormItem.chooseCityList[0].name;
					}
					this.$nextTick(() => {
						this.renderCanvans();
					});
				}
			},
			// 绘制图表
			renderCanvans() {
				this.chart = echarts.init(document.getElementById("canvas"));
				let xData = [];
				let yData = [];

				let filterArr = [];
				let filterData = {};

				const data = this.getSearchData(-1);
				//检测数据
				if (!this.checkData(data)) return;

				let listApi = this.tabsIndex == 0 ? getData : get_enterprise_data;

				listApi(data).then((data) => {
					// this.tableHead = data.dynamicRecord.heads;
					this.canvasData = data.records.map((item) => {
						item.landArea = item.landArea ? parseInt(item.landArea) : 0;
						item.planArea = item.planArea ? parseInt(item.planArea) : 0;
						item.startPrice = item.startPrice ?
							parseInt(item.startPrice / 10000) :
							0;
						item.transactionPrice = item.transactionPrice ?
							parseInt(item.transactionPrice / 10000) :
							0;
						item.floorPrice = item.floorPrice ? parseInt(item.floorPrice) : 0;
						item.regionPrice = item.regionPrice ? parseInt(item.regionPrice) : 0;
						item.premiumRate = item.premiumRate ?
							Number((item.premiumRate * 100).toFixed(2)) :
							0;
						return item;
					});
					let addUnit = false;
					let unit = this.selectRenderIndex;
					if (this.selectRenderIndex === "溢价率") {
						unit += "%";
					}

					this.canvasData.map((item) => {
						if (item.provinceName.indexOf(this.selectRenderCity) > -1) {
							let selectKey = "";
							for (let key in this.indexOptions) {
								let item = this.indexOptions[key].name;
								if (item == this.selectRenderIndex) {
									selectKey = key;
									if (this.indexOptions[key].unit && !addUnit) {
										addUnit = true;
										unit += this.indexOptions[key].unit;
									}
									break;
								}
							}
							item[selectKey] = Number(item[selectKey].toFixed(2));
							if (!filterData[item.landTime]) {
								xData.push(item.landTime);
								filterData[item.landTime] = {
									total: item[selectKey],
								};
								let filterItem = item.landUseName;
								filterData[item.landTime][filterItem] = item[selectKey];
								if (filterArr.indexOf(filterItem) == -1) {
									filterArr.push(filterItem);
								}
							} else {
								let now = filterData[item.landTime];
								now.total += item[selectKey];
								let filterItem = item.landUseName;
								now[filterItem] = item[selectKey];
								if (filterArr.indexOf(filterItem) == -1) {
									filterArr.push(filterItem);
								}
							}
							yData.push(item[selectKey]);
						}
					});
					// var myChart = this.$echarts.init(document.querySelectorAll('.canvas-box'));
					this.canvasOption = {
						// backgroundColor: "#011c3a",
						color: [
							"rgb(0,176,240)",
							"rgb(255,196,0)",
							"rgb(255,0,0)",
							"rgb(146,208,80)",
							"rgb(255,255,0)",
							"rgb(112,48,160)",
						],
						legend: {
							left: "center",
							bottom: "0",
							data: filterArr,
						},
						tooltip: {
							trigger: "axis",
							axisPointer: {
								type: "cross",
							},
						},
						xAxis: {
							data: xData.reverse(),
							axisLine: {
								lineStyle: {
									// color: "#0177d4"
								},
							},
							axisLabel: {
								// color: "#fff",
								fontSize: 14,
							},
						},
						yAxis: {
							name: unit,
							nameTextStyle: {
								// color: "#fff",
								fontSize: 16,
							},
							axisLine: {
								lineStyle: {
									// color: "#0177d4"
								},
							},
							axisLabel: {
								// color: "#fff",
								fontSize: 16,
							},
							splitLine: {
								show: false,
								lineStyle: {
									// color: "#0177d4"
								},
							},
							// interval: 500,
							// max: 5000
						},
						// series: [
						//   {
						//     type: "bar",
						//     barWidth: 18,
						//     data: yData.reverse()
						//   }
						// ]
						series: filterArr.map((item, index) => {
							return {
								type: "bar",
								name: item,
								barWidth: 18,
								stack: "sum",
								itemStyle: {
									color: this.canvasColor[index],
								},
								data: xData.map((time) => {
									return filterData[time][item];
								}),
								yAxisIndex: 0,
							};
						}),
					};
					//初始化数据
					this.chart.setOption(this.canvasOption);
				});
			},
			setCanvasOption() {
				let xData = [];
				let yData = [];

				let filterArr = [];
				let filterData = {};

				let addUnit = false;
				let unit = this.selectRenderIndex;
				if (this.selectRenderIndex === "溢价率") {
					unit += "%";
				}

				this.canvasData.map((item) => {
					if (item.provinceName.indexOf(this.selectRenderCity) > -1) {
						let selectKey = "";
						for (let key in this.indexOptions) {
							let item = this.indexOptions[key].name;
							if (item == this.selectRenderIndex) {
								selectKey = key;
								if (this.indexOptions[key].unit && !addUnit) {
									addUnit = true;
									unit += this.indexOptions[key].unit;
								}
								break;
							}
						}
						item[selectKey] = Number(item[selectKey].toFixed(2));
						if (!filterData[item.landTime]) {
							xData.push(item.landTime);
							filterData[item.landTime] = {
								total: item[selectKey],
							};
							let filterItem = item.landUseName;
							filterData[item.landTime][filterItem] = item[selectKey];
							if (filterArr.indexOf(filterItem) == -1) {
								filterArr.push(filterItem);
							}
						} else {
							let now = filterData[item.landTime];
							now.total += item[selectKey];
							let filterItem = item.landUseName;
							now[filterItem] = item[selectKey];
							if (filterArr.indexOf(filterItem) == -1) {
								filterArr.push(filterItem);
							}
						}
						yData.push(item[selectKey]);
					}
				});
				this.canvasOption.legend.data = filterArr;
				this.canvasOption.xAxis.data = xData.reverse();
				this.canvasOption.yAxis.name = unit;
				// this.canvasOption.series[0].data = yData.reverse();
				this.canvasOption.series = filterArr.map((item, index) => {
					return {
						type: "bar",
						name: item,
						barWidth: 18,
						stack: "sum",
						itemStyle: {
							color: this.canvasColor[index],
						},
						data: xData.map((time) => {
							return filterData[time][item];
						}),
						yAxisIndex: 0,
					};
				});

				this.chart.setOption(this.canvasOption);
			},
			manageModalSwitch() {
				this.showModalManage = !this.showModalManage;
			},
			cityModalSwitch() {
				// this.showCityManage = !this.showCityManage;
			},
			devModalSwitch() {
				// this.showDevManage = !this.showDevManage;
			},
			chooseTemplate(item) {

				let formItem = this.nowFormItem;
				let params = JSON.parse(item.templateParams);
				for (let key in params) {
					formItem[key] = params[key];
				}
				for (let key in this.$refs.areaSelect.nowFormItem) {
					this.$refs.areaSelect.nowFormItem[key] = formItem[key]
				}
				formItem.currentIndex = 1;
				this.getList();
			},

			chooseProvince(item) {
				getLandSingleSelectCity({
					id: item,
				}).then((data) => {
					this.nowFormItem.cityList = data;
					this.nowFormItem.city = "";
					this.nowFormItem.regionList = [];
					this.nowFormItem.region = [];
				});
			},
			async chooseProvince2(item) {
				getLandSingleSelectCity({
					id: item,
				}).then((data) => {
					let arr = Object.assign([], this.cityList);
					arr.push(...data);
					this.cityList = this.deteleObject(arr);
					this.formItem2.cityList = data;
				});
			},
			deteleObject(obj) {
				var uniques = [];
				var stringify = {};
				for (var i = 0; i < obj.length; i++) {
					var keys = Object.keys(obj[i]);
					keys.sort(function(a, b) {
						return Number(a) - Number(b);
					});
					var str = "";
					for (var j = 0; j < keys.length; j++) {
						str += JSON.stringify(keys[j]);
						str += JSON.stringify(obj[i][keys[j]]);
					}
					if (!stringify.hasOwnProperty(str)) {
						uniques.push(obj[i]);
						stringify[str] = true;
					}
				}
				// uniques = uniques;
				return uniques;
			},
			chooseSelectCity(item) {
				getLandSingleSelectRegion({
					id: item,
				}).then((data) => {
					this.nowFormItem.regionList = data;
					this.nowFormItem.region = [];
				});
			},
			// 全选checkbox用
			handleCheckAll(val, list, key) {
				let item = this.nowFormItem;
				// 已经全选
				if (val) {
					item[key] = [];
				} else {
					if (key == "indexOptions") {
						item[key] = Object.keys(list);
					} else {
						item[key] = list.map((item) => {
							return item.id || item.key;
						});
					}
				}
			},
			handleCheckCompose() {
				let item = this.nowFormItem;
				let arr = item.sellTypes;
				if (this.checkSellCompose) {
					del(3);
					del(4);
					del(5);
				} else {
					add(3);
					add(4);
					add(5);
				}

				function del(i) {
					var index = arr.indexOf(i);
					arr.splice(index, 1);
				}

				function add(i) {
					var index = arr.indexOf(i);
					if (index == -1) {
						arr.push(i);
					}
				}
			},

			// 城市穿梭框相关
			radioChange(val) {
				let item = this.nowFormItem;
				if (val != "single") {
					item.province = "";
					item.city = "";
					item.region = [];
				} else {
					item.searchCity = "";
					item.multiCityRadio = "";
					item.multiCityList = [];
					item.chooseCityList = [];
				}
			},
			selectTransferProvince(id) {
				let item = this.nowFormItem;
				item.chooseCityList = [];
				if (id == 1) {
					// getProvinceQuery()
					// .then(data => {
					// 	this.areaList = data
					// })
					item.multiCityRadio = "";
					item.multiCityList = this.landProvinceList.map((item) => {
						return {
							id: item.areaId,
							name: item.name,
						};
					});
				} else if (id == 2) {
					item.multiCityRadio = 1;
					this.getMultiCity(item.multiCityRadio);
				}
				if (this.status2021) {

					this.status2021 = false;
				}
			},
			getMultiCity(grade) {
				return new Promise((resolve, reject) => {
					getCityQuery({
						grade,
					}).then((res) => {

						this.nowFormItem.multiCityList = res.map((item) => {
							return {
								id: item.areaId,
								name: item.name,
							};
						});
						resolve();
					});
				});
				// this.nowFormItem.multiCityList = list.map(city =>{
				// 	return {
				// 		id : city.id,
				// 		name : city.name
				// 	}
				// });
			},
			getMultiCity2(grade) {
				if (this.nowFormItem.areaValue !== 2) {
					this.nowFormItem.areaValue = 2
					this.selectTransferProvince(2)
				}
				getCityQuery({
					grade,
				}).then((res) => {
					this.nowFormItem.chooseCityList = res.map((item) => {
						return {
							id: item.areaId,
							name: item.name,
						};
					});
					// this.chooseAreaList = data;
					this.nowFormItem.multiCityRadio = 1
					this.getMultiCity(1)
				});
			},
			getMultiCom(id) {
				let formItem = this.formItem2;
				formItem.chooseCityList = [];
				formItem.searchCity = "";
				formItem.filterCityList = [];
				// 选择集团
				if (id == 1) {
					dimSrfJt({
						// csIds : formItem.city || []
					}).then((res) => {
						formItem.multiCityList = res.map((com) => {
							return {
								id: com.qyId,
								name: com.qymc,
							};
						});
					});
				} else {
					dimSrf({
							csIds: formItem.city || [],
						})
						.then((res) => {
							this.formItem2.multiCityList = res.map((com) => {
								return {
									id: com.id,
									name: com.enterprise,
								};
							});
						})
						.catch(() => {
							formItem.multiCityList = [];
						});
				}
				// let list = this.enterprise[key];
				// this.nowFormItem.multiCityList = list.list.map(city => {
				//   return {
				//     id: city.id,
				//     name: city.gsmc
				//   };
				// });
			},
			searchCity() {
				let nowFormItem = this.nowFormItem;
				let val = nowFormItem.searchCity;
				if (!val) return;

				searchCity({
					name: val,
					areaType: nowFormItem.areaValue == 1 ? "省" : "市",
				}).then((info) => {
					nowFormItem.multiCityList = info.map((item) => {
						return {
							id: item.areaId,
							name: item.name,
						};
					});
				});
			},
			searchCom() {
				let nowFormItem = this.nowFormItem;
				let val = nowFormItem.searchCity;
				let reg = new RegExp(val);

				let arr = nowFormItem.multiCityList.filter((item) => {
					return reg.test(item.name);
				});

				if (val) {
					nowFormItem.filterCityList = arr;
				} else {
					nowFormItem.filterCityList = [];
				}
			},
			chooseCity(item) {
				if (
					this.nowFormItem.chooseCityList.some((i) => i.id == item.id) ||
					this.nowFormItem.multiCityRadio > 5
				)
					return;
				// if (item.checked) return;
				// item.checked = true;
				this.nowFormItem.chooseCityList.push(item);

			},
			delCity(item, index) {
				item.checked = false;
				this.nowFormItem.chooseCityList.splice(index, 1);
			},
			delComCity(item, index) {
				this.nowFormItem.city.splice(index, 1);
			},
			delChooseCity() {
				let item = this.nowFormItem;
				item.province = "";
				item.city = "";
				item.region = [];
			},
			clearChooseCity() {
				let item = this.nowFormItem;
				item.chooseCityList.forEach((item) => {
					item.checked = false;
				});
				item.chooseCityList = [];
			},
			mutliSelectAll() {
				if (this.nowFormItem.filterCityList.length) {
					this.nowFormItem.filterCityList.forEach((item) => {
						this.chooseCity(item);
					});
				} else {
					this.nowFormItem.multiCityList.forEach((item) => {
						this.chooseCity(item);
					});
				}
			},

			// 已选提交的操作
			delTime() {
				let item = this.nowFormItem;
				item.timePeriod = "";
				item.quarterStart = "";
				item.quarterEnd = "";
				item.timeStart = "";
				item.timeEnd = "";
				item.optionTime = "";
			},
			changeTimePeriod() {
				let item = this.nowFormItem;
				// item.quarterStart = '';
				// item.quarterEnd = '';
				// item.timeStart = '';
				// item.timeEnd = '';
				// item.optionTime = '';
				item.timeStart && this.changeTime(1);
				item.timeEnd && this.changeTime(2);
			},
			checkTime() {
				if (
					this.nowFormItem.timePeriod &&
					this.nowFormItem.timeStart &&
					this.nowFormItem.timeEnd
				) {
					if (this.nowFormItem.timePeriod == 2) {
						if (this.nowFormItem.quarterStart && this.nowFormItem.quarterEnd) {
							return (this.nowFormItem.optionTime =
								`${this.nowFormItem.formatTimeStart}${this.nowFormItem.quarterStart}至${this.nowFormItem.formatTimeEnd}${this.nowFormItem.quarterEnd}`
							);
						} else {
							return (this.nowFormItem.optionTime = "");
						}
					} else {
						return (this.nowFormItem.optionTime =
							`${this.nowFormItem.formatTimeStart}至${this.nowFormItem.formatTimeEnd}`);
					}
				}
				return (this.nowFormItem.optionTime = "");
			},
			changeTime(type) {
				let timePeriod = this.nowFormItem.timePeriod;
				type == 1 &&
					(() => {
						let timeStart = this.nowFormItem.timeStart;
						if (typeof timeStart != "object") {
							if (timeStart.indexOf("上午") || timeStart.indexOf("下午")) {
								timeStart = localeDateStringtoTimestamp(timeStart);
							} else {
								timeStart = new Date(timeStart);
							}
						}
						if (timePeriod == 4) {
							this.nowFormItem.formatTimeStart = `${timeStart.getFullYear()}${
              timeStart.getMonth() + 1 < 10
                ? `0${this.nowFormItem.timeStart.getMonth() + 1}`
                : timeStart.getMonth() + 1
            }${timeStart.getDate()}`;
						} else if (timePeriod == 1) {
							this.nowFormItem.formatTimeStart = `${timeStart.getFullYear()}-${
              timeStart.getMonth() + 1 < 10
                ? `0${timeStart.getMonth() + 1}`
                : timeStart.getMonth() + 1
            }`;
						} else {
							this.nowFormItem.formatTimeStart = `${timeStart.getFullYear()}`;
						}
					})();
				type == 2 &&
					(() => {
						let timeEnd = this.nowFormItem.timeEnd;
						if (typeof timeEnd != "object") {
							if (timeEnd.indexOf("上午") || timeEnd.indexOf("下午")) {
								timeEnd = localeDateStringtoTimestamp(timeEnd);
							} else {
								timeEnd = new Date(timeEnd);
							}
						}
						if (timePeriod == 4) {
							this.nowFormItem.formatTimeEnd = `${timeEnd.getFullYear()}${
              timeEnd.getMonth() + 1 < 10
                ? `0${this.nowFormItem.timeEnd.getMonth() + 1}`
                : timeEnd.getMonth() + 1
            }${timeEnd.getDate()}`;
						} else if (timePeriod == 1) {
							this.nowFormItem.formatTimeEnd = `${timeEnd.getFullYear()}-${
              timeEnd.getMonth() + 1 < 10
                ? `0${timeEnd.getMonth() + 1}`
                : timeEnd.getMonth() + 1
            }`;
						} else {
							this.nowFormItem.formatTimeEnd = `${timeEnd.getFullYear()}`;
						}
					})();
				this.checkTime();
			},
			search() {
				this.nowFormItem.currentIndex = 1;
				this.getList();
			},

			getSearchData(size) {
				if(this.$refs.areaSelect){
					for (let key in this.$refs.areaSelect.nowFormItem) {
						this.nowFormItem[key] = this.$refs.areaSelect.nowFormItem[key]
					}
				}

				let item = JSON.parse(JSON.stringify(this.nowFormItem));
				let params = {
					cityEnter: this.tabsIndex + 1,
					current: size == -1 ? 1 : item.currentIndex,
					landTime: item.optionTime && item.optionTime.split("至"),
					landUses: item.landUses,
					sellTypes: item.sellTypes,
					size: size == -1 ? 100000000 : item.pageSize,
					timePeriod: item.timePeriod,
					tradingStatus: item.tradingStatus,
				};

				if (this.tabsIndex == 1) {
					params.provinceIds = item.city;
					let arr = item.chooseCityList.map((item) => {
						return item.id;
					});
					if (item.multiCityRadio == 1) {
						params.qyIds = arr;
					} else {
						params.enterpriseIds = arr;
					}
				} else {
					if (item.radio == "single") {
						if (item.region.length) {
							params.provinceIds = item.region;

						} else {
							if (!item.city) {
								this.$msg.error({
									type: "remind",
									code: 4026,
								});

								params.provinceIds = "";
							}
							params.provinceIds = item.city && [item.city];
						}
					} else if (item.radio == "multiple") {
						params.provinceIds = item.chooseCityList.map((item) => {
							return item.id;
						});
					}
				}

				// 合并展示还是单个展示
				if (this.showDetail == 2 && size != -1) {
					let tmpStr = "";
					params.landUses.sort().map((id) => {
						let tmp = this.allLandUse.find((item) => {
							return item.id == id;
						});
						if (!tmpStr) {
							tmpStr = tmp.name;
						} else {
							tmpStr += `+${tmp.name}`;
						}
					});

					let tmp = this.allLandUse.find((item) => {
						return item.name == tmpStr;
					});
					if (tmp && this.tabsIndex == 0) {
						params.landUses = [tmp.id];
					}

					params.hb = 1;
					// params.landUses = [1,2,3,4];
					// params.hb=1
				}
				return params;
			},
			saveTemplate() {
				for (let key in this.$refs.areaSelect.nowFormItem) {
					this.nowFormItem[key] = this.$refs.areaSelect.nowFormItem[key]
				}
				this.saveSearchData = JSON.stringify(this.nowFormItem);
				this.showModalManage = true;
			},
			checkData(param) {
				if (!this.nowFormItem.timeStart) {
					this.$msg.error({
						type: "remind",
						code: 4011,
					});

					this.tableLoading = false;
					return false;
				}
				if (!this.nowFormItem.timeEnd) {
					this.$msg.error({
						type: "remind",
						code: 4012,
					});

					this.tableLoading = false;
					return false;
				}
				if (this.nowFormItem.radio == "single" && !this.nowFormItem.city) {
					this.$msg.error({
						type: "remind",
						code: 4026,
					});

					this.tableLoading = false;
					return false;
				}
				if (param.provinceIds.length == 0) {
					this.$msg.error({
						type: "remind",
						code: 4013,
					});

					this.tableLoading = false;
					return false;
				}

				if (param.landUses.length == 0) {
					this.$msg.error({
						type: "remind",
						code: 4016,
					});

					this.tableLoading = false;
					return false;
				}
				if (!checkDate(param.landTime[0], param.landTime[1])) {
					this.tableLoading = false;
					this.$msg.error({
						type: "remind",
						code: 4015,
					});

					return false;
				}
				if (param.cityEnter == 2 && param.enterpriseIds.length == 0) {
					this.tableLoading = false;
					this.$msg.error({
						type: "remind",
						code: 4017,
					});

					return false;
				}
				if (this.nowFormItem.indexOptions.length == 0) {
					this.tableLoading = false;
					this.$msg.error({
						type: "remind",
						code: 4014,
					});

					return false;
				}
				return true;
			},
			getList() {
				if (this.theadType == 2) {
					this.renderCanvans();
					return;
				}
				let item = this.nowFormItem;
				const param = this.getSearchData();
				//检测数据
				if (!this.checkData(param)) return;

				this.loading = true;
				item.tableData = [];

				let listApi = this.tabsIndex == 0 ? getData : get_enterprise_data;

				listApi(param)
					.then((data) => {

						item.total = data.total;
						item.tableData = data.records;
					})
					.finally(() => {
						this.loading = false;
					})
					.catch((err) => {
						this.$msg.error({
							text: err.data,
						});
					});
			},
			pageSizeChange(size) {
				this.nowFormItem.current = 1;
				this.nowFormItem.pageSize = size;
				this.getList();
			},
			pageChange(size) {
				this.nowFormItem.currentIndex = size;
				let target = document.querySelector(".ivu-table-body");
				target && (target.scrollTop = 0);
				this.getList();
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "~@/style/common.scss";

	.place-count-box {
		width: 100%;
		min-height: calc(100vh - 80px);
		padding-top: 20px;
		background: #fff;

		.centerBox {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			min-width: 1200px;
			padding: 0 20px;

			.tab {
				display: flex;
				align-items: center;
				width: 100%;
				height: 46px;
				margin-bottom: 20px;
				color: #666;
				font-size: 16px;
				border: 1px solid #e3e3e5;
				background-color: #fbfcff;

				&-item {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 126px;
					height: 100%;
					cursor: pointer;

					&.active {
						color: #00b6ff;

						&::after {
							position: absolute;
							width: 100%;
							height: 3px;
							left: 0;
							bottom: 0;
							background-color: #00b6ff;
							content: "";
						}
					}
				}
			}

			.data-box {
				display: flex;
				width: 100%;
			}

			.option-box {
				flex: 1;
				width: 50%;
				padding: 26px;
				margin-right: 20px;
				background-color: #fff;
				border: 1px solid #e3e3e5;

				&:last-child {
					margin-right: 0;
				}

				/deep/ .ivu-form-item-label {
					width: 56px;
					padding-right: 0;
					margin-right: 20px;
					text-align: right;
				}

				.ivu-checkbox-group {
					display: inline-block;
				}

				.com-city-box {
					.line {
						@include flex(flex-start, flex-start);
					}

					.select-box {
						position: relative;

						.place {
							position: absolute;
							top: 0;
							left: 8px;
						}
					}

					/deep/ .multiple-select {
						.ivu-tag {
							display: none;
						}
					}

					.show-city-box {
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;

						span {
							margin-right: 5px;
						}
					}
				}
			}

			.timeTop {
				display: flex;

				.leftTopRadio {
					margin-top: -10px;
					width: 80px;
					margin-left: 22px;
					line-height: 50px;
				}

				.multiple-select {
					white-space: nowrap;

					.ivu-select-selection {
						overflow: hidden;
					}
				}
			}

			.areaTransfer {
				display: flex;
				align-items: center;
				margin-left: 12px;

				.leftTransfer,
				.rightTransfer {
					width: 287px;
					height: 207px;
					border: 1px solid rgba(190, 199, 219, 1);
					border-radius: 5px;
				}

				.leftTransfer {
					background: rgba(246, 249, 255, 0.6);
					padding: 8px;
					box-sizing: border-box;

					.leftTransferBottom {
						display: flex;
						height: 156px;
					}

					.leftRadio {
						flex: 1;
						overflow: auto;

						.ivu-radio-group {
							display: block;
						}

						.ivu-radio-wrapper {
							display: block;
							margin-bottom: 5px;
							color: #333333;
						}
					}

					.rightBox {
						width: 180px;
						height: 156px;
						background: rgba(255, 255, 255, 1);
						border: 1px solid rgba(228, 228, 228, 1);
						border-radius: 5px;
						padding: 8px 0;
						box-sizing: border-box;
						overflow: auto;

						.item {
							width: 100%;
							font-size: 16px;
							text-indent: 10px;
							white-space: nowrap;
							cursor: pointer;
							color: #333333;

							.active {
								color: #4373e8;
							}

							&:hover {
								color: #4373e8;
							}
						}
					}
				}

				.leftTransferSearch {
					@include flex;
					margin-bottom: 5px;

					.ivu-input-wrapper {
						width: 220px;
					}

					.btn-all {
						margin-left: auto;
						margin-right: 5px;
						cursor: pointer;
					}
				}

				.centerBtn {
					margin: 0 14px;
					cursor: pointer;
				}

				.rightTransfer {
					border: 1px solid rgba(228, 228, 228, 1);
					overflow: hidden;

					.topBtn {
						width: 100%;
						height: 36px;
						background: #f6f9ff;
						display: flex;
						padding: 0 15px;
						box-sizing: border-box;
						align-items: center;
						justify-content: space-between;

						span {
							color: #999999;
							cursor: pointer;

							&:hover {
								color: #333;
							}
						}
					}

					.itemBox {
						width: 100%;
						padding: 0 15px;
						box-sizing: border-box;
						overflow: auto;
						height: 170px;

						.ivu-tag {
							background: #ededed !important;
						}

						.ivu-tag-checked {
							background: #00b6ff !important;
						}
					}
				}

				&.company {
					margin-left: 0;

					.ivu-radio-wrapper {
						display: block;
						// margin-bottom: 5px;
					}

					.leftTransfer,
					.rightTransfer {
						width: 45%;
						height: 265px;

						.leftTransferBottom {
							height: 200px;
						}

						.leftRadio,
						.rightBox {
							height: 100%;
						}

						.itemBox {
							height: 228px;

							/deep/ .ivu-tooltip {
								width: 100%;

								.ivu-tooltip-rel {
									width: 100%;

									.item {
										@include flex;
										width: 100%;

										span {
											@include ellipsis;
											width: 100%;
										}

										i {
											margin-left: 5px;
											flex-shrink: 0;
										}
									}
								}
							}
						}
					}
				}
			}

			.cityManage {
				display: flex;

				.manageBtn {
					flex-shrink: 0;
					text-align: center;
					margin: 10px 0 0 5px;
					// cursor: pointer;
					cursor: not-allowed;

					&:hover {
						color: #00b6ff;
					}
				}

				.rightMangeChoose {
					margin: 20px 0 0;
					white-space: nowrap;
				}
			}

			.btn-box {
				@include flex;
				margin: 18px 0 30px;
				color: #333;
				font-size: 14px;

				.btn {
					@include flex;
					width: 120px;
					height: 40px;
					margin-right: 14px;
					border: 1px solid rgba(228, 228, 228, 1);
					border-radius: 5px;
					cursor: pointer;

					&.check {
						width: 144px;
						color: #fff;
						background-color: #00b6ff;
					}

					&.last-child {
						margin-right: 0;
					}
				}
			}

			.choose-box {
				@include flex(flex-start);
				width: 100%;
				margin-bottom: 20px;

				.title {
					flex-shrink: 0;
					color: #333;
				}

				.des {}
			}

			.table-box {
				width: 100%;

				.top {
					@include flex(flex-end);
					position: relative;
					height: 40px;
					padding-right: 100px;
					background-color: #f0f0f0;
					width: 100%;
					position: relative;

					.download-bar {
						position: absolute;
						top: 7px;
						left: 10px;
					}

					.icon-box {
						width: 90px;
						height: 100%;
						position: absolute;
						top: 0;
						right: 0;
						font-size: 0;
						display: flex;

						.icon {
							@include flex;
							position: relative;
							width: 45px;
							height: 100%;
							cursor: pointer;

							&:nth-child(1) {
								&::before {
									@include bgSrc("table.png");
								}

								&.active::before {
									@include bgSrc("table-a.png");
								}
							}

							&:nth-child(2) {
								&::before {
									@include bgSrc("chart.png");
								}

								&.active::before {
									@include bgSrc("chart-a.png");
								}
							}

							&.active {
								background-color: #a2abbb;

								&::after {
									content: "";
									width: 0;
									height: 0;
									border-top: 7px solid transparent;
									border-left: 7px solid transparent;
									border-right: 7px solid transparent;
									border-bottom: 7px solid #fff;
									position: absolute;
									left: 50%;
									bottom: 0;
									transform: translateX(-50%);
								}
							}

							&::before {
								width: 25px;
								height: 24px;
								content: "";
							}
						}
					}

					.select-box {
						position: relative;

						/deep/ .ivu-select-selected-value {
							display: none;
						}

						.place {
							position: absolute;
							top: 3px;
							left: 10px;
							pointer-events: none;
						}
					}

					.table-opt {
						@include flex;
					}
				}

				.ivu-page {
					position: relative;
					margin: 15px 0;
					text-align: right;
				}
			}

			.canvas-box {
				width: 100%;
				height: 560px;

				canvas {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
</style>
