<template>
	<Modal v-model="showCityManage" class-name="vertical-center-modal" footer-hide @on-cancel="cancelHandle"
		:width="width">
		<p slot="header" style="font-size: 18px;font-weight: bold;color: #333333;" class="modalHeader">
			模板管理
		</p>
		<div class="modalContent">
			<div class="leftContent">
				<div class="title">设置</div>
				<div class="add">
					新城市群
					<Icon type="ios-add-circle-outline" size="20" />
				</div>
				<div class="item" v-for="(item,index) in 0" :key="index">
					<span>
						一线城市
					</span>
					<Icon type="md-trash" class="delBtn" @click="delM" size="20" />
				</div>
			</div>

			<div class="rightContent">
				<div class="title">基本信息</div>
				<Form :model="formItem" :label-width="80">
					<FormItem label="模板名称">
						<Input v-model="formItem.input" size="large" placeholder="请输入城市群名称" style="width: 181px" />
					</FormItem>
					<FormItem label="模板描述">
						<Input v-model="formItem.input" size="large" type="textarea" maxlength="50" show-word-limit
							:rows="2" placeholder="请输入城市群描述文字" style="width: 371px;" />
					</FormItem>
				</form>

				<div class="areaTransfer">
					<div class="leftTransfer">
						<div class="leftTransferSearch">
							<Input v-model="searchArea" placeholder="请选择地区" @on-blur="searchAreaHandle"
								style="width: 183px;margin-bottom:7px;" />
							<span style="margin-left:13px;color:#999;
                cursor: pointer;">全选</span>
						</div>

						<div class="leftTransferBottom">
							<div class="leftRadio">
								<Select v-model="formItem.timeSelect" placeholder="省份"
									style="width:74px;margin-right:10px;">
									<Option v-for="item in timeOptions" :value="item.value" :key="item.value">
										{{ item.name }}</Option>
								</Select>
								<RadioGroup v-model="formItem.radio">
									<Radio label="one">一线城市</Radio>
									<Radio label="two">二线城市</Radio>
									<Radio label="three">三、四线城市</Radio>
									<Radio label="four">所有城市</Radio>
								</RadioGroup>
							</div>
							<div class="rightBox">
								<div class="item" v-for="(item,index) in 0" :key="index">北京</div>
							</div>
						</div>
					</div>
					<Icon type="md-arrow-round-forward" class="centerBtn" />
					<div class="rightTransfer">
						<div class="item">
							北京
							<Icon color="#BEC2C7" type="md-close" class="closeBtn" />
						</div>

					</div>
				</div>
				<div class="bottomBtn" style="text-align:center;margin-top:10px;">
					<Button size="small" style="margin-right:18px;width:52px;" type="error">取消</Button>
					<Button size="small" style="width:52px;" @click="addCity" type="info">添加</Button>
				</div>
			</div>
		</div>
	</Modal>
</template>

<script>
	// @ is an alias to /src

	export default {
		name: 'cityManage',
		props: {
			width: {
				type: Number,
				default: 712
			}
		},
		computed: {
			showCityManage: {
				get() {
					return this.$store.state.modal.showCityManage;
				},
				set(newValue) {
					this.$store.state.modal.showCityManage = newValue;
				}
			}
		},
		data() {
			return {
				formItem: {
					input: '',
					select: '',
					radio: 'male',
					checkbox: [],
					switch: true,
					date: '',
					time: '',
					slider: [20, 50],
					textarea: ''
				},
				timeOptions: [{
						name: "日",
						value: 1
					},
					{
						name: "月",
						value: 2
					},
					{
						name: "年",
						value: 3
					}
				],

				formItemList2: {
					area: ''
				},
				searchArea: ''
			}
		},
		methods: {
			delM() {

			},
			cancelHandle() {
				this.$emit('close')
			},
			addCity() {
				// this.$Message.info('功能未开放');
				this.$msg.error({
					text: '功能未开放'
				})
				this.showCityManage = false;
			},
			searchAreaHandle() {

			}
		}
	}
</script>

<style lang="scss" scoped>
	.vertical-center-modal {
		display: flex;
		align-items: center;
		justify-content: center;

		.ivu-modal {
			top: 0;
		}
	}

	.modalHeader {
		position: relative;
		text-indent: 10px;

		&::after {
			content: "";
			position: absolute;
			width: 4px;
			height: 20px;
			background: rgba(0, 182, 255, 1);
			top: 0;
			left: 0;
		}
	}

	.modalContent {
		width: 675px;
		height: 418px;
		border: 1px solid #e0e8ed;
		border-radius: 1px;
		display: flex;

		.leftContent {
			width: 129px;
			height: 100%;
			overflow: auto;
			background: #f8fafb;

			.title {
				font-size: 14px;
				font-weight: bold;
				line-height: 36px;
				text-indent: 13px;
			}

			.add,
			.item {
				display: flex;
				height: 30px;
				width: calc(100% - 26px);
				margin: 0 auto;
				align-items: center;
				justify-content: space-between;
				cursor: pointer;

				span:hover {
					color: #00b6ff;
				}

				.delBtn:hover {
					color: #00b6ff;
				}
			}

			.add {
				font-weight: bold;

				&:hover {
					color: #00b6ff;
				}
			}
		}

		.rightContent {
			flex: 1;
			height: 100%;
			.ivu-form-item {
				margin-bottom: 7px;
			}

			.title {
				height: 36px;
				border-bottom: 1px solid #e6e6e6;
				line-height: 36px;
				text-indent: 13px;
				margin-bottom: 15px;
			}

			.areaTransfer {
				display: flex;
				align-items: center;
				margin-left: 12px;

				.leftTransfer,
				.rightTransfer {
					width: 227px;
					height: 207px;
					border: 1px solid rgba(190, 199, 219, 1);
					border-radius: 5px;
				}

				.leftTransfer {
					width: 261px;
					background: rgba(246, 249, 255, 0.6);
					padding: 8px;
					box-sizing: border-box;

					.leftTransferBottom {
						display: flex;
					}

					.leftRadio {
						flex: 1;

						.ivu-radio-wrapper {
							margin-bottom: 14px;
							color: #333333;
						}
					}

					.rightBox {
						width: 102px;
						height: 156px;
						background: rgba(255, 255, 255, 1);
						border: 1px solid rgba(228, 228, 228, 1);
						border-radius: 5px;
						padding: 8px 0;
						box-sizing: border-box;
						overflow: auto;

						.item {
							width: 100%;
							font-size: 16px;
							text-indent: 16px;
							cursor: pointer;
							color: #333333;

							.active {
								color: #4373e8;
							}

							&:hover {
								color: #4373e8;
							}
						}
					}
				}

				.centerBtn {
					margin: 0 14px;
					cursor: pointer;
				}

				.rightTransfer {
					border: 1px solid rgba(228, 228, 228, 1);
					padding: 4px 15px;
					overflow: auto;

					.item {
						float: left;
						height: 32px;
						background: rgba(237, 237, 237, 1);
						border-radius: 5px;
						padding: 0 7px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin: 15px 15px 0 0;

						.closeBtn {
							margin-left: 12px;
							cursor: pointer;

							&:hover {
								transform: scale(1.1);
							}
						}
					}
				}
			}
		}
	}
</style>
