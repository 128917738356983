import { post, get } from '@/utils/http'

export function getClass (params) {
  return post({ url: '/service-insight/index_data/indexClass', params })
}
export function getAttr (params) {
  return post({ url: '/service-insight/index_data/indexAttr', params })
}
export function getCityQuery (params) {
  return get({ url: '/service-insight/index_data/cityQuery', params })
}
export function getProvinceQuery (params) {
  return get({ url: '/service-insight/index_data/provinceQuery', params })
}
export function select (params) {
  return post({ url: '/service-insight/index_data/select', params })
}
export function selectCity (params) {
  return get({ url: '/service-insight/index_data/selectCity', params })
}
export function getSingleSelectCity (params) {
  return get({ url: '/service-insight/index_data/singleSelectCity', params })
}
export function getSingleSelectProvince (params) {
  return get({ url: '/service-insight/index_data/singleSelectProvince', params })
}
export function getSingleSelectRegion (params) {
  return get({ url: '/service-insight/index_data/singleSelectRegion', params })
}

