<template>
	<div class="areaSelect">
		<div v-if="showSingle" class="timeTop">
			<div class="leftTopRadio">
				<RadioGroup v-model="nowFormItem.radio" @on-change="radioChange">
					<Radio label="single">单城市</Radio>
					<Radio label="multiple">多城市</Radio>
				</RadioGroup>
			</div>
			<div class="rightSelect">
				<Select v-model="nowFormItem.province" placeholder="省份" :disabled="nowFormItem.radio != 'single'"
					@on-change="chooseProvince" style="width: 74px; margin-right: 7px">
					<Option v-for="item in ProvinceList" :value="item.areaId" :key="item.areaId">
						{{ item.name }}
					</Option>
				</Select>
				<Select v-model="nowFormItem.city" placeholder="城市" :disabled="nowFormItem.radio != 'single'"
					@on-change="chooseSelectCity" style="width: 74px; margin-right: 7px">
					<Option v-for="item in nowFormItem.cityList" :value="item.areaId" :key="item.areaId">
						{{ item.name }}
					</Option>
				</Select>
			</div>
		</div>

		<div class="areaTransfer">
			<div class="leftTransfer">
				<div class="leftTransferSearch">
					<Input v-model="nowFormItem.searchCity" placeholder="输入关键字搜索" @on-change="searchCity"
						:disabled="nowFormItem.radio != 'multiple'" />
					<span class="btn-all" @click="mutliSelectAll">全选</span>
				</div>

				<div class="leftTransferBottom">
					<div class="leftRadio">
						<Select v-model="nowFormItem.areaValue" placeholder="省份"
							:disabled="nowFormItem.radio != 'multiple'" @on-change="selectTransferProvince"
							style="width: 74px; margin-right: 7px">
							<Option v-for="item in areaSelect" :value="item.id" :key="item.id" :disabled='item.id == 1 && isHideProvince'>
								{{ `${item.name}` }}
							</Option>
						</Select>
						<RadioGroup v-model="nowFormItem.multiCityRadio" @on-change="getMultiCity">
							<Radio v-for="(item, key) in cityOptions" :key="key"
								:disabled="nowFormItem.areaValue != 2 ||nowFormItem.radio == 'single'" :label="item.id">
								{{ item.label }}
							</Radio>
						</RadioGroup>
					</div>
					<div class="rightBox">
						<div class="item" v-for="(item, index) in nowFormItem.multiCityList" @click="chooseCity(item)"
							:key="index">
							{{ item.name }}
						</div>
					</div>
				</div>
			</div>

			<Icon type="md-arrow-round-forward" class="centerBtn" />

			<div class="rightTransfer">
				<div class="topBtn">
					已选
					<span @click="clearChooseCity">清空</span>
				</div>
				<div class="itemBox">
					<Tag closable class="item" color="#00B6FF" v-for="(item, index) in nowFormItem.chooseCityList"
						:key="index" @on-close="delCity(item, index)">{{ item.name }}</Tag>
				</div>
			</div>
		</div>
		<div class="cityManage">
			<RadioGroup v-model="nowFormItem.multiCityRadio2" @on-change="getMultiCity2" class="rightMangeChoose">
				<Radio v-for="(item, key) in cityOptions2" :key="key" :label="item.id"
					:disabled="nowFormItem.areaValue != 2 ||nowFormItem.radio == 'single'">
					<span>{{ item.label }}</span>
				</Radio>
			</RadioGroup>
		</div>
	</div>


</template>

<script>
	import {
		getCityQuery
	} from "@/api/dataQuery";
	import {
		getLandSingleSelectCity,
		getLandSingleSelectRegion,
		searchCity,
		getEnterprise,
	} from "@/api/public";
	export default {
		name: 'areaSelect',
		data() {
			return {
				nowFormItem: {
					areaValue: 2,
					multiCityList: [],
					province: "",
					city: "",
					region: [],
					cityList: [],
					regionList: [],
					radio: "",
					multiCityRadio: 1,
					multiCityRadio2: '',
					filterCityList: [],
					chooseCityList: [],
				},
				areaSelect: [{
						name: "省",
						id: 1,
					},
					{
						name: "市",
						id: 2,
					},
				],
				cityOptions: [{
						label: "所有城市",
						id: 4,
					},
					{
						label: "一线城市",
						id: 1,
					},
					{
						label: "二线城市",
						id: 2,
					},
					{
						label: "三线城市",
						id: 3,
					},
				],
				cityOptions2: [{
						label: "粤港澳大湾区",
						id: 10,
					},
					{
						label: "京津冀",
						id: 6,
					},
					{
						label: "长三角",
						id: 7,
					},
					{
						label: "成渝",
						id: 8,
					},
					{
						label: "长江中游",
						id: 9,
					},
				],

			}
		},

		computed: {

		},
		props:{
			radio:{
				type:String
			},
			ProvinceList:{
				type:Array
			},
			isHideProvince:{
				type:Boolean,
				default:false
			},
			showSingle:{
				type:Boolean,
				default:true
			}
		},
		async mounted() {
			await this.getMultiCity(this.nowFormItem.multiCityRadio);
			this.chooseCity(this.nowFormItem.multiCityList[2]);
			this.nowFormItem.radio = this.radio
		},
		methods: {
			// 城市穿梭框相关
			radioChange(val) {
				let item = this.nowFormItem;
				if (val != "single") {
					item.province = "";
					item.city = "";
					item.region = [];
					item.multiCityRadio = 1;
					this.getMultiCity(item.multiCityRadio)

				} else {
					item.searchCity = "";
					item.multiCityRadio = "";
					item.multiCityList = [];
					item.chooseCityList = [];

				}
			},
			chooseProvince(item) {
				getLandSingleSelectCity({
					id: item,
				}).then((data) => {
					this.nowFormItem.cityList = data;
					this.nowFormItem.city = "";
					this.nowFormItem.regionList = [];
					this.nowFormItem.region = [];
				});
			},
			chooseSelectCity(item) {
				getLandSingleSelectRegion({
					id: item,
				}).then((data) => {
					this.nowFormItem.regionList = data;
					this.nowFormItem.region = [];
				});
			},
			searchCity() {
				let nowFormItem = this.nowFormItem;
				let val = nowFormItem.searchCity;
				if (!val) return;

				searchCity({
					name: val,
					areaType: nowFormItem.areaValue == 1 ? "省" : "市",
				}).then((info) => {
					nowFormItem.multiCityList = info.map((item) => {
						return {
							id: item.areaId,
							name: item.name,
						};
					});
				});
			},
			mutliSelectAll() {
				if (this.nowFormItem.filterCityList.length) {
					this.nowFormItem.filterCityList.forEach((item) => {
						this.chooseCity(item);
					});
				} else {
					this.nowFormItem.multiCityList.forEach((item) => {
						this.chooseCity(item);
					});
				}
			},
			selectTransferProvince(id) {
				let item = this.nowFormItem;
				item.chooseCityList = [];
				if (id == 1) {
					// getProvinceQuery()
					// .then(data => {
					// 	this.areaList = data
					// })
					item.multiCityRadio = "";
					item.multiCityList = this.ProvinceList.map((item) => {
						return {
							id: item.areaId,
							name: item.name,
						};
					});
				} else if (id == 2) {
					item.multiCityRadio = 1;
					this.getMultiCity(item.multiCityRadio);
				}
				if (this.status2021) {

					this.status2021 = false;
				}
			},
			getMultiCity(grade) {
				this.nowFormItem.multiCityRadio2 = ''
				return new Promise((resolve, reject) => {
					getCityQuery({
						grade,
					}).then((res) => {
						this.nowFormItem.multiCityList = res.map((item) => {
							return {
								id: item.areaId,
								name: item.name,
							};
						});
						resolve();
					});
				});

			},
			getMultiCity2(grade) {
				if (this.nowFormItem.areaValue !== 2) {
					this.nowFormItem.areaValue = 2
					this.selectTransferProvince(2)
				}
				getCityQuery({
					grade,
				}).then((res) => {
					this.nowFormItem.chooseCityList = res.map((item) => {
						return {
							id: item.areaId,
							name: item.name,
						};
					});
					// this.chooseAreaList = data;
					this.nowFormItem.multiCityRadio = ''
				});
			},
			chooseCity(item) {
				if (
					this.nowFormItem.chooseCityList.some((i) => i.id == item.id) ||
					this.nowFormItem.multiCityRadio > 5
				)
					return;
				this.nowFormItem.chooseCityList.push(item);
			},
			clearChooseCity() {
				let item = this.nowFormItem;
				item.chooseCityList.forEach((item) => {
					item.checked = false;
				});
				this.nowFormItem.chooseCityList = [];
				if (this.$parent.nowFormItem.chooseCityList) {
					this.$parent.nowFormItem.chooseCityList = this.nowFormItem.chooseCityList
				}
			},
			delCity(item, index) {
				item.checked = false;
				this.nowFormItem.chooseCityList.splice(index, 1);
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "~@/style/common.scss";

	.timeTop {
		display: flex;

		.leftTopRadio {
			margin-top: -10px;
			width: 80px;
			margin-left: 22px;
			line-height: 50px;
		}

		.multiple-select {
			white-space: nowrap;

			.ivu-select-selection {
				overflow: hidden;
			}
		}
	}

	.areaTransfer {
		display: flex;
		align-items: center;
		margin-left: 12px;

		.leftTransfer,
		.rightTransfer {
			width: 287px;
			height: 207px;
			border: 1px solid rgba(190, 199, 219, 1);
			border-radius: 5px;
		}

		.leftTransfer {
			background: rgba(246, 249, 255, 0.6);
			padding: 8px;
			box-sizing: border-box;

			.leftTransferBottom {
				display: flex;
				height: 156px;
			}

			.leftRadio {
				flex: 1;
				overflow: auto;

				.ivu-radio-group {
					display: block;
				}

				.ivu-radio-wrapper {
					display: block;
					margin-bottom: 5px;
					color: #333333;
				}
			}

			.rightBox {
				width: 180px;
				height: 156px;
				background: rgba(255, 255, 255, 1);
				border: 1px solid rgba(228, 228, 228, 1);
				border-radius: 5px;
				padding: 8px 0;
				box-sizing: border-box;
				overflow: auto;

				.item {
					width: 100%;
					font-size: 16px;
					text-indent: 10px;
					white-space: nowrap;
					cursor: pointer;
					color: #333333;

					.active {
						color: #4373e8;
					}

					&:hover {
						color: #4373e8;
					}
				}
			}
		}

		.leftTransferSearch {
			@include flex;
			margin-bottom: 5px;

			.ivu-input-wrapper {
				width: 220px;
			}

			.btn-all {
				margin-left: auto;
				margin-right: 5px;
				cursor: pointer;
			}
		}

		.centerBtn {
			margin: 0 14px;
			cursor: pointer;
		}

		.rightTransfer {
			border: 1px solid rgba(228, 228, 228, 1);
			overflow: hidden;

			.topBtn {
				width: 100%;
				height: 36px;
				background: #f6f9ff;
				display: flex;
				padding: 0 15px;
				box-sizing: border-box;
				align-items: center;
				justify-content: space-between;

				span {
					color: #999999;
					cursor: pointer;

					&:hover {
						color: #333;
					}
				}
			}

			.itemBox {
				width: 100%;
				padding: 0 15px;
				box-sizing: border-box;
				overflow: auto;
				height: 170px;

				.ivu-tag {
					background: #ededed !important;
				}

				.ivu-tag-checked {
					background: #00b6ff !important;
				}
			}
		}

		&.company {
			margin-left: 0;

			.ivu-radio-wrapper {
				display: block;
				/* margin-bottom: 5px; */
			}

			.leftTransfer,
			.rightTransfer {
				width: 45%;
				height: 265px;

				.leftTransferBottom {
					height: 200px;
				}

				.leftRadio,
				.rightBox {
					height: 100%;
				}

				.itemBox {
					height: 228px;

					/deep/ .ivu-tooltip {
						width: 100%;

						.ivu-tooltip-rel {
							width: 100%;

							.item {
								@include flex;
								width: 100%;

								span {
									@include ellipsis;
									width: 100%;
								}

								i {
									margin-left: 5px;
									flex-shrink: 0;
								}
							}
						}
					}
				}
			}
		}
	}

	.cityManage {
		display: flex;

		.manageBtn {
			flex-shrink: 0;
			text-align: center;
			margin: 10px 0 0 5px;
			/* cursor: pointer; */
			cursor: not-allowed;

			&:hover {
				color: #00b6ff;
			}
		}

		.rightMangeChoose {
			margin: 20px 0 0;
			white-space: nowrap;
		}
	}
</style>
